import styles from './index.module.scss';

const Loader = () => (
  <div className={styles.spinner} data-cy="loader">
    <div className="col-12 text-center">
      <div className={styles.ellipsis}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default Loader;
