import { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import SubSlider from './SubSlider';
import { fetchTranslateListing } from '../../../store/slider/action';
import { translateListingsSelector } from '../../../store/slider/selectors';
import { renderArea, renderPrice } from '../../../utils/helpers/formater';

const MultipleItems = ({
  title,
  data,
  horizontal,
  settings,
  isShowIndicators,
}) => {
  const dispatch = useDispatch();
  const translateListings = useSelector(translateListingsSelector);
  const [listings, setTranslateListings] = useState();

  useEffect(() => {
    if (Object.keys(translateListings).length) {
      setTranslateListings(translateListings);
    } else {
      dispatch(fetchTranslateListing());
    }
  }, [translateListings, dispatch]);

  const translateDetailInfo = useCallback((value, obj) => {
    if (value === undefined || obj === undefined) {
      return '';
    }
    return obj[value] || value;
  }, []);
  const renderListItem = useCallback(
    (item, index) => (
      <div key={index} data-cy="slider">
        <div
          className={classNames(
            'g-brd-gray-light-v3',
            styles.houseBlock,
            { [styles.singleSlide]: data.length === 1 },
            { [styles.twoSlide]: data.length === 2 },
          )}
        >
          <SubSlider
            isShowIndicators={isShowIndicators}
            horizontal={horizontal}
            images={item.images}
            items={item}
          />
          <p className="mt-4">
            {`${translateDetailInfo(
              item.objectClass.toUpperCase(),
              listings?.data?.object_class,
            )} in ${item?.city}`}
          </p>
          <div
            className="d-flex"
            style={{ width: '80%', marginBottom: '15px' }}
          >
            <div className={styles.info} />
            <div style={{ marginLeft: '15px' }}>
              <div className={classNames('d-flex align-items-center')}>
                <p
                  style={{ height: '30px' }}
                  className={styles.horizontalStyleSlider}
                >
                  {`${item?.zipCode}, ${
                    item?.district ? item.district : title
                  }`}{' '}
                </p>
              </div>
              <p
                style={{ height: '36px' }}
                className={styles.horizontalStyleSlider}
              >{`${item?.numberOfRooms} Zimmer`}</p>
              <p
                style={{ height: '25px' }}
                className={styles.horizontalStyleSlider}
              >
                {renderArea(item?.livingSpace)}
              </p>
              <p
                style={{
                  height: '30px',
                  marginTop: '5px',
                  carouselMyfontWeight: 'bold',
                  fontSize: '24px',
                }}
                className={styles.horizontalStyleSlider}
              >
                {renderPrice(item)}
              </p>
            </div>
          </div>
          <Link
            href="/objektdetails/[id]"
            as={`/objektdetails/${item?.id}`}
            key={index}
          >
            <a
              /* eslint-disable-next-line max-len */
              className={classNames(
                'btn btn-block g-bg-secondary g-font-weight-600 rounded-0 g-px-18 g-py-15',
                styles.link,
              )}
              data-cy="slider-link"
            >
              Objektdetails
            </a>
          </Link>
        </div>
      </div>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      renderPrice,
      listings,
      translateDetailInfo,
      isShowIndicators,
      horizontal,
      title,
      data,
    ],
  );

  return (
    <div className={classNames({ sliderHorizontal: horizontal })}>
      {data.length ? (
        <div className="container-carousel">
          <div className="carouselMy">
            <Slider
              {...settings}
              className={classNames({ myStyle: data.length === 2 })}
            >
              {data.map(renderListItem)}
            </Slider>
          </div>
        </div>
      ) : (
        <p
          style={{
            height: '200px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Not found
        </p>
      )}
    </div>
  );
};

MultipleItems.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  horizontal: PropTypes.bool,
  settings: PropTypes.object.isRequired,
  isShowIndicators: PropTypes.bool,
};

MultipleItems.defaultProps = {
  title: '',
  data: [],
  horizontal: false,
  isShowIndicators: false,
};

export default MultipleItems;
