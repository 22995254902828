import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

import classNames from 'classnames';
import { useRouter } from 'next/router';
import styles from './index.module.scss';
import Index from '../FallbackImage';

const SubSlider = ({
  images,
  isHorizontalStyleImage,
  isHouseDetail,
  items,
  isShowIndicators,
  isCard,
}) => {
  const router = useRouter();

  const redirectToDetailView = useCallback(() => {
    if (isHouseDetail) return;
    router.push(`/objektdetails/${items.id}`);
  }, [isHouseDetail, items, router]);

  const renderImage = useCallback(
    (item, idx) => (
      <Carousel.Item key={idx}>
        <Index isHouseDetail={isHouseDetail} src={item} alt="property" />
      </Carousel.Item>
    ),
    [isHouseDetail],
  );

  return useMemo(() => {
    if (images?.length) {
      if (images?.length === 1 || isCard) {
        return (
          <Index
            redirectToDetailView={redirectToDetailView}
            isHorizontalStyleImage={isHorizontalStyleImage}
            isHouseDetail={isHouseDetail}
            src={images[0]}
            alt="property"
            isCard={isCard}
          />
        );
      }
      return (
        <div
          className={classNames(styles.indicators, {
            [styles.isShowIndicators]: isShowIndicators,
          })}
        >
          <Carousel interval={null}>{images?.map(renderImage)}</Carousel>
        </div>
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        <img
          loading="lazy"
          role="presentation"
          onClick={redirectToDetailView}
          src="/assets/img/fallback.jpg"
          alt="fallback"
          className={classNames(
            styles.defaultImg,
            { [styles.houseImage]: isHouseDetail },
            { [styles.isShowIndicators]: isShowIndicators },
          )}
        />
      </div>
    );
  }, [
    isShowIndicators,
    redirectToDetailView,
    isHouseDetail,
    isHorizontalStyleImage,
    images,
    renderImage,
    isCard,
  ]);
};

SubSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  isShowIndicators: PropTypes.bool,
};

SubSlider.defaultProps = {
  detailList: false,
  isShowIndicators: false,
};

export default SubSlider;
