import axios from 'axios';
import {
  FETCH_TRANSLATE_LISTING,
  FETCH_TRANSLATE_LISTING_SUCCESS,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchTranslateListing = () => async (dispatch) => {
  dispatch({ type: FETCH_TRANSLATE_LISTING });

  const data = await axios.get('/api/listings/slider');
  dispatch({ type: FETCH_TRANSLATE_LISTING_SUCCESS, response: data });
};
