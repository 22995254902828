import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../Slider/index.module.scss';
import Loader from '../Loader';

const Index = ({
  src,
  alt,
  isHouseDetail,
  redirectToDetailView,
  isHorizontalStyleImage,
  isCard,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const img = useRef(null);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback(() => {
    setError(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    img.current = new Image();
    img.current.src = src;
    img.current.onerror = onError;
    img.current.onload = onLoad;
    return () => {
      img.current.onerror = null;
      img.current.onload = null;
    };
  }, [src, onLoad, onError]);

  return (
    // eslint-disable-next-line no-nested-ternary
    loading ? (
      <div
        className={classNames(styles.subSliderImg, {
          [styles.houseImageFallBack]: isHouseDetail,
        })}
      >
        <Loader />
      </div>
    ) : error ? (
      <img
        src="/assets/img/fallback.jpg"
        alt="Not Found"
        onClick={redirectToDetailView}
        {...props}
        className={classNames(styles.subSliderImg, {
          [styles.houseImageFallBack]: isHouseDetail,
        })}
        loading="lazy"
        role="presentation"
        data-cy="slider-img"
      />
    ) : (
      <div
        className={classNames(
          styles.subSliderImg,
          { [styles.houseImage]: isHouseDetail },
          { [styles.active]: isHorizontalStyleImage },
          { [styles.houseImage]: isHouseDetail },
        )}
        style={isCard && { height: '100%', maxHeight: '303px' }}
      >
        <img
          role="presentation"
          onClick={redirectToDetailView}
          alt={alt}
          src={src}
          loading="lazy"
          data-cy="slider-img"
        />
      </div>
    )
  );
};

Index.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isHouseDetail: PropTypes.bool,
  redirectToDetailView: PropTypes.func,
  isHorizontalStyleImage: PropTypes.bool,
  isCard: PropTypes.bool,
};
Index.defaultProps = {
  isHouseDetail: false,
  isHorizontalStyleImage: false,
  redirectToDetailView: () => {},
  isCard: undefined,
};

export default Index;
